import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let existingAbility = [{ action: 'manage', subject: 'all' }] 


	const abilities = [
		{ action: 'manage', subject: "data:dashboard:nav", },
		{ action: 'manage', subject: "data:dashboard:read", },

		{ action: 'manage', subject: "data:account:nav", },
		{ action: 'manage', subject: "data:account:profile:update", },
		{ action: 'manage', subject: "data:account:log", },
		{ action: 'manage', subject: "data:account:active:environment:update", },
		{ action: 'manage', subject: "data:account:active:language:update", },
		{ action: 'manage', subject: "data:account:password:update", },
		{ action: 'manage', subject: "data:account:totp:state:update", },
		{ action: 'manage', subject: "data:account:totp:confirm:update", },
		{ action: 'manage', subject: "data:account:service:translate:update", },

		{ action: 'manage', subject: "data:asset:nav", },
		{ action: 'manage', subject: "data:asset:read", },
		{ action: 'manage', subject: "data:asset:create", },
		{ action: 'manage', subject: "data:asset:update", },
		{ action: 'manage', subject: "data:asset:delete", },
		{ action: 'manage', subject: "data:asset:log", },

		{ action: 'manage', subject: "data:claim:nav", },
		{ action: 'manage', subject: "data:claim:read", },
		{ action: 'manage', subject: "data:claim:create", },
		{ action: 'manage', subject: "data:claim:update", },
		{ action: 'manage', subject: "data:claim:delete", },
		{ action: 'manage', subject: "data:claim:log", },

		{ action: 'manage', subject: "data:claim:create:status:draft", },
		{ action: 'manage', subject: "data:claim:create:status:publish", },
		{ action: 'manage', subject: "data:claim:update:status:draft", },
		{ action: 'manage', subject: "data:claim:update:status:publish", },		
		{ action: 'manage', subject: "data:claim:delete:status:draft", },
		{ action: 'manage', subject: "data:claim:delete:status:publish", },				

		{ action: 'manage', subject: "data:coupon-group:nav", },
		{ action: 'manage', subject: "data:coupon-group:read", },
		{ action: 'manage', subject: "data:coupon-group:create", },
		{ action: 'manage', subject: "data:coupon-group:update", },
		{ action: 'manage', subject: "data:coupon-group:delete", },
		{ action: 'manage', subject: "data:coupon-group:log", },

		{ action: 'manage', subject: "data:coupon:nav", },
		{ action: 'manage', subject: "data:coupon:read", },
		{ action: 'manage', subject: "data:coupon:create", },
		{ action: 'manage', subject: "data:coupon:update", },
		{ action: 'manage', subject: "data:coupon:delete", },
		{ action: 'manage', subject: "data:coupon:log", },

		{ action: 'manage', subject: "data:course:nav", },
		{ action: 'manage', subject: "data:course:read", },
		{ action: 'manage', subject: "data:course:create", },
		{ action: 'manage', subject: "data:course:update", },
		{ action: 'manage', subject: "data:course:delete", },
		{ action: 'manage', subject: "data:course:log", },
		{ action: 'manage', subject: "data:course:generate:primary", },
		{ action: 'manage', subject: "data:course:generate:lesson", },

		{ action: 'manage', subject: "data:course:create:status:draft", },
		{ action: 'manage', subject: "data:course:create:status:publish", },
		{ action: 'manage', subject: "data:course:update:status:draft", },
		{ action: 'manage', subject: "data:course:update:status:publish", },		
		{ action: 'manage', subject: "data:course:delete:status:draft", },
		{ action: 'manage', subject: "data:course:delete:status:publish", },				

		{ action: 'manage', subject: "data:environment:nav", },
		{ action: 'manage', subject: "data:environment:read", },
		{ action: 'manage', subject: "data:environment:create", },
		{ action: 'manage', subject: "data:environment:update", },
		{ action: 'manage', subject: "data:environment:delete", },
		{ action: 'manage', subject: "data:environment:log", },

		{ action: 'manage', subject: "data:environment:create:status:draft", },
		{ action: 'manage', subject: "data:environment:create:status:publish", },
		{ action: 'manage', subject: "data:environment:update:status:draft", },
		{ action: 'manage', subject: "data:environment:update:status:publish", },		
		{ action: 'manage', subject: "data:environment:delete:status:draft", },
		{ action: 'manage', subject: "data:environment:delete:status:publish", },	

		{ action: 'manage', subject: "data:language:nav", },
		{ action: 'manage', subject: "data:language:read", },
		{ action: 'manage', subject: "data:language:create", },
		{ action: 'manage', subject: "data:language:update", },
		{ action: 'manage', subject: "data:language:delete", },
		{ action: 'manage', subject: "data:language:log", },

		{ action: 'manage', subject: "data:lesson:nav", },
		{ action: 'manage', subject: "data:lesson:read", },
		{ action: 'manage', subject: "data:lesson:create", },
		{ action: 'manage', subject: "data:lesson:update", },
		{ action: 'manage', subject: "data:lesson:delete", },
		{ action: 'manage', subject: "data:lesson:log", },

		{ action: 'manage', subject: "data:lesson:create:status:draft", },
		{ action: 'manage', subject: "data:lesson:create:status:publish", },
		{ action: 'manage', subject: "data:lesson:update:status:draft", },
		{ action: 'manage', subject: "data:lesson:update:status:publish", },		
		{ action: 'manage', subject: "data:lesson:delete:status:draft", },
		{ action: 'manage', subject: "data:lesson:delete:status:publish", },				

		{ action: 'manage', subject: "data:notice:nav", },
		{ action: 'manage', subject: "data:notice:read", },
		{ action: 'manage', subject: "data:notice:create", },
		{ action: 'manage', subject: "data:notice:update", },
		{ action: 'manage', subject: "data:notice:delete", },
		{ action: 'manage', subject: "data:notice:log", },

		{ action: 'manage', subject: "data:notice:create:status:draft", },
		{ action: 'manage', subject: "data:notice:create:status:publish", },
		{ action: 'manage', subject: "data:notice:update:status:draft", },
		{ action: 'manage', subject: "data:notice:update:status:publish", },		
		{ action: 'manage', subject: "data:notice:delete:status:draft", },
		{ action: 'manage', subject: "data:notice:delete:status:publish", },				

		{ action: 'manage', subject: "data:organisation:nav", },
		{ action: 'manage', subject: "data:organisation:read", },
		{ action: 'manage', subject: "data:organisation:create", },
		{ action: 'manage', subject: "data:organisation:update", },
		{ action: 'manage', subject: "data:organisation:delete", },
		{ action: 'manage', subject: "data:organisation:log", },

		{ action: 'manage', subject: "data:practice:nav", },
		{ action: 'manage', subject: "data:practice:read", },
		{ action: 'manage', subject: "data:practice:create", },
		{ action: 'manage', subject: "data:practice:update", },
		{ action: 'manage', subject: "data:practice:delete", },
		{ action: 'manage', subject: "data:practice:log", },
		{ action: 'manage', subject: "data:practice:generate:primary", },
		{ action: 'manage', subject: "data:practice:generate:claim", },		

		{ action: 'manage', subject: "data:practice:create:status:draft", },
		{ action: 'manage', subject: "data:practice:create:status:publish", },
		{ action: 'manage', subject: "data:practice:update:status:draft", },
		{ action: 'manage', subject: "data:practice:update:status:publish", },		
		{ action: 'manage', subject: "data:practice:delete:status:draft", },
		{ action: 'manage', subject: "data:practice:delete:status:publish", },				

		{ action: 'manage', subject: "data:process:nav", },
		{ action: 'manage', subject: "data:process:read", },
		{ action: 'manage', subject: "data:process:create", },
		{ action: 'manage', subject: "data:process:update", },
		{ action: 'manage', subject: "data:process:delete", },
		{ action: 'manage', subject: "data:process:terminate", },
		{ action: 'manage', subject: "data:process:log", },

		{ action: 'manage', subject: "data:role:nav", },
		{ action: 'manage', subject: "data:role:read", },
		{ action: 'manage', subject: "data:role:create", },
		{ action: 'manage', subject: "data:role:update", },
		{ action: 'manage', subject: "data:role:delete", },
		{ action: 'manage', subject: "data:role:log", },

		{ action: 'manage', subject: "data:service:nav", },
		{ action: 'manage', subject: "data:service:read", },
		{ action: 'manage', subject: "data:service:create", },
		{ action: 'manage', subject: "data:service:update", },
		{ action: 'manage', subject: "data:service:delete", },
		{ action: 'manage', subject: "data:service:log", },

		{ action: 'manage', subject: "data:stylesheet:nav", },
		{ action: 'manage', subject: "data:stylesheet:read", },
		{ action: 'manage', subject: "data:stylesheet:create", },
		{ action: 'manage', subject: "data:stylesheet:update", },
		{ action: 'manage', subject: "data:stylesheet:delete", },
		{ action: 'manage', subject: "data:stylesheet:log", },

		{ action: 'manage', subject: "data:tip:nav", },
		{ action: 'manage', subject: "data:tip:read", },
		{ action: 'manage', subject: "data:tip:create", },
		{ action: 'manage', subject: "data:tip:update", },
		{ action: 'manage', subject: "data:tip:delete", },
		{ action: 'manage', subject: "data:tip:log", },

		{ action: 'manage', subject: "data:tip:create:status:draft", },
		{ action: 'manage', subject: "data:tip:create:status:publish", },
		{ action: 'manage', subject: "data:tip:update:status:draft", },
		{ action: 'manage', subject: "data:tip:update:status:publish", },		
		{ action: 'manage', subject: "data:tip:delete:status:draft", },
		{ action: 'manage', subject: "data:tip:delete:status:publish", },				

		{ action: 'manage', subject: "data:user:nav", },
		{ action: 'manage', subject: "data:user:read", },
		{ action: 'manage', subject: "data:user:create", },
		{ action: 'manage', subject: "data:user:update", },
		{ action: 'manage', subject: "data:user:delete", },
		{ action: 'manage', subject: "data:user:log", },

		{ action: 'manage', subject: "data:user:create:use:super", },
		{ action: 'manage', subject: "data:user:create:use:admin", },
		{ action: 'manage', subject: "data:user:create:use:public", },
		{ action: 'manage', subject: "data:user:update:use:super", },
		{ action: 'manage', subject: "data:user:update:use:admin", },
		{ action: 'manage', subject: "data:user:update:use:public", },
		{ action: 'manage', subject: "data:user:delete:use:super", },
		{ action: 'manage', subject: "data:user:delete:use:admin", },
		{ action: 'manage', subject: "data:user:delete:use:public", },
	];




export const handleAbility = (role) => {
	console.log("role", role)
	if (!role || !role?.privileges) return null;
	if (role.privileges.find(p=>p === "super")) return [{ action: 'manage', subject: 'all' }]
	
	const abilities = role.privileges.map(p=>{
		return { action: 'manage', subject: p }
	})
	if (abilities.find(a=>['data:course:nav', 'data:lesson:nav', 'data:practice:nav', 'data:claim:nav', 'data:tip:nav', 'data:notice:nav', 'data:stylesheet:nav'].includes(a.subject) )){
		abilities.push({ action: 'manage', subject: 'nav:content-management', })
	}
	if (abilities.find(a=>['data:user:nav', 'data:environment:nav',  'data:coupons-group:nav', 'data:asset:nav', 'data:process:nav', 'data:coupon:nav', ].includes(a.subject) )){
		abilities.push({ action: 'manage', subject: 'nav:organisation-management', })
	}
	if (abilities.find(a=>['data:organisation:nav', 'data:user:public:nav', 'data:language:nav', 'data:role:nav', 'data:service:nav',].includes(a.subject) )){
		abilities.push({ action: 'manage', subject: 'nav:administrative-management', })
	}

	console.log("abilities", abilities)

	return abilities;
}

try {
	const userData = JSON.parse(localStorage.getItem('userData'))
	// console.log("userData", userData?.role)
	// const userData = {}
	// const existingAbility = userData ? userData.ability : null
	// existingAbility = userData ? [{ action: 'manage', subject: 'all' }] : null
	existingAbility = userData 
		? handleAbility(userData?.role) 
		: null
} catch (e){
	console.log(e)
}                  



export default new Ability(existingAbility || initialAbility)
